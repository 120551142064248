<template>
  <section class="dashboard section is-full-touch is-full mobile">
    <div class="container is-fluid-desktop is-full-touch is-full-mobile">
      <div class="is-hidden-desktop is-hidden-widescreen" id="sbarmobile">
        <Mobilemenu />
      </div>
      <div class="columns">
        <div class="column is-narrow-desktop is-hidden-mobile is-hidden-touch sbar" id="sbar">
          <Sidebar />
        </div>
        <div class="column is-full-mobile">
          <div v-if="lesson">
            <div class="is-flex-desktop is-flex-widescreen is-full-mobile is-justify-content-space-between block">
              <div>
                <h1 class="title mb-1">
                  Lesson {{ lesson.index }}: {{ lesson.title }}
                </h1>
                <nav aria-label="breadcrumbs" class="breadcrumb is-large">
                  <ul>
                    <li>
                      <router-link :to="{ name: 'EducationPDFLessons' }">
                        <span>Lessons</span>
                      </router-link>
                    </li>
                    <li>
                      <a href="#">
                        <span>{{ lesson.category }}</span>
                      </a>
                    </li>
                  </ul>
                </nav>
                <p>{{ lesson.description }}</p>
              </div>
              <div class="field is-grouped">
                <div class="control">
                  <button
                    @click="showChart = !showChart"
                    class="button is-dark"
                  >
                    Toggle Chart
                  </button>
                </div>
                <div class="control">
                  <button
                      @click="togglePrintMode"
                      class="button is-dark"
                  >
                    <span class="icon">
                      <i class="fa fa-print fa-lg"></i>
                    </span>
                  </button>
                </div>
                <p v-if="admin && !edit" class="control">
                  <button @click="editMode" class="button is-dark">Edit</button>
                </p>
                <p v-if="admin && edit" class="control">
                  <button @click="save" class="button is-success">Save</button>
                </p>
                <p v-if="admin && edit" class="control">
                  <button @click="cancelEdit" class="button is-danger">
                    Cancel
                  </button>
                </p>
              </div>
            </div>
            <div class="card block">
              <div class="card-content">
                <form v-if="admin && edit" class="block" @submit.prevent>
                  <div class="field">
                    <label class="label">Title</label>
                    <p class="control">
                      <input
                        v-model.trim="lesson.title"
                        class="input"
                        type="text"
                        placeholder="Title"
                      />
                    </p>
                  </div>
                  <div class="field">
                    <label class="label">Description</label>
                    <div class="control">
                      <textarea
                        v-model="lesson.description"
                        class="textarea"
                        placeholder="Textarea"
                      ></textarea>
                    </div>
                  </div>
                  <div class="field">
                    <label class="label">Category</label>
                    <p class="control">
                      <input
                        v-model.trim="lesson.category"
                        class="input"
                        type="text"
                        placeholder="Category"
                      />
                    </p>
                  </div>
                  <div class="field">
                    <label class="label">Index</label>
                    <p class="control">
                      <input
                        v-model.number="lesson.index"
                        class="input"
                        type="number"
                        placeholder="Index"
                      />
                    </p>
                  </div>
                </form>
                <editor-floating-menu
                  :editor="editor"
                  v-slot="{ commands, isActive, menu }"
                >
                  <div
                    class="editor__floating-menu"
                    :class="{ 'is-active': edit }"
                    :style="`top: ${menu.top}px`"
                  >
                    <button
                      class="menubar__button"
                      :class="{ 'is-active': isActive.italic() }"
                      @click="showImagePrompt(commands.image)"
                    >
                      <i class="fas fa-image"></i>
                    </button>

                    <button
                      class="menubar__button"
                      :class="{ 'is-active': isActive.bold() }"
                      @click="commands.bold"
                    >
                      <i class="fas fa-bold"></i>
                    </button>

                    <button
                      class="menubar__button"
                      :class="{ 'is-active': isActive.italic() }"
                      @click="commands.italic"
                    >
                      <i class="fas fa-italic"></i>
                    </button>

                    <button
                      class="menubar__button"
                      :class="{ 'is-active': isActive.strike() }"
                      @click="commands.strike"
                    >
                      <i class="fas fa-strikethrough"></i>
                    </button>

                    <button
                      class="menubar__button"
                      :class="{ 'is-active': isActive.underline() }"
                      @click="commands.underline"
                    >
                      <i class="fas fa-underline"></i>
                    </button>

                    <button
                      class="menubar__button"
                      :class="{ 'is-active': isActive.code() }"
                      @click="commands.code"
                    >
                      <i class="fas fa-code"></i>
                    </button>

                    <button
                      class="menubar__button"
                      :class="{ 'is-active': isActive.paragraph() }"
                      @click="commands.paragraph"
                    >
                      <i class="fas fa-paragraph"></i>
                    </button>

                    <button
                      class="menubar__button"
                      :class="{ 'is-active': isActive.heading({ level: 1 }) }"
                      @click="commands.heading({ level: 1 })"
                    >
                      H1
                    </button>

                    <button
                      class="menubar__button"
                      :class="{ 'is-active': isActive.heading({ level: 2 }) }"
                      @click="commands.heading({ level: 2 })"
                    >
                      H2
                    </button>

                    <button
                      class="menubar__button"
                      :class="{ 'is-active': isActive.heading({ level: 3 }) }"
                      @click="commands.heading({ level: 3 })"
                    >
                      H3
                    </button>

                    <button
                      class="menubar__button"
                      :class="{ 'is-active': isActive.heading({ level: 4 }) }"
                      @click="commands.heading({ level: 4 })"
                    >
                      H4
                    </button>

                    <button
                      class="menubar__button"
                      :class="{ 'is-active': isActive.heading({ level: 5 }) }"
                      @click="commands.heading({ level: 5 })"
                    >
                      H5
                    </button>

                    <button
                      class="menubar__button"
                      :class="{ 'is-active': isActive.heading({ level: 6 }) }"
                      @click="commands.heading({ level: 6 })"
                    >
                      H6
                    </button>

                    <button
                      class="menubar__button"
                      :class="{ 'is-active': isActive.bullet_list() }"
                      @click="commands.bullet_list"
                    >
                      <i class="fas fa-list-ul"></i>
                    </button>

                    <button
                      class="menubar__button"
                      :class="{ 'is-active': isActive.ordered_list() }"
                      @click="commands.ordered_list"
                    >
                      <i class="fas fa-list-ol"></i>
                    </button>

                    <button
                      class="menubar__button"
                      :class="{ 'is-active': isActive.blockquote() }"
                      @click="commands.blockquote"
                    >
                      <i class="fas fa-quote-left"></i>
                    </button>

                    <button
                      class="menubar__button"
                      :class="{ 'is-active': isActive.code_block() }"
                      @click="commands.code_block"
                    >
                      <i class="fas fa-terminal"></i>
                    </button>

                    <button
                      class="menubar__button"
                      @click="commands.horizontal_rule"
                    >
                      ----
                    </button>

                    <button class="menubar__button" @click="commands.undo">
                      <i class="fas fa-undo"></i>
                    </button>

                    <button class="menubar__button" @click="commands.redo">
                      <i class="fas fa-redo"></i>
                    </button>
                  </div>
                </editor-floating-menu>
                <div v-show="showChart" id="live-chart" class="block"></div>
                <editor-content :editor="editor" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import {
  Editor,
  EditorContent,
  EditorMenuBar,
  EditorFloatingMenu,
} from "tiptap";
import {
  Blockquote,
  CodeBlock,
  HardBreak,
  Heading,
  HorizontalRule,
  OrderedList,
  BulletList,
  ListItem,
  TodoItem,
  TodoList,
  Bold,
  Code,
  Italic,
  Link,
  Strike,
  Underline,
  History,
  Image,
} from "tiptap-extensions";
import Sidebar from "@/components/Sidebar.vue";
import Mobilemenu from "@/components/Mobilemenu.vue";
import Icon from "@/components/Icon";
import { auth, db } from "@/firebase";

export default {
  name: "EducationVideoLessonsLesson",
  components: {
    Sidebar,
    EditorContent,
    EditorMenuBar,
    EditorFloatingMenu,
    Icon,
    Mobilemenu,
  },
  data() {
    return {
      admin: false,
      edit: false,
      lesson: null,
      showChart: true,
      printMode: false,
      editor: new Editor({
        editable: false,
        extensions: [
          new Image(),
          new Blockquote(),
          new BulletList(),
          new CodeBlock(),
          new HardBreak(),
          new Heading({ levels: [1, 2, 3, 4, 5, 6] }),
          new HorizontalRule(),
          new ListItem(),
          new OrderedList(),
          new TodoItem(),
          new TodoList(),
          new Link(),
          new Bold(),
          new Code(),
          new Italic(),
          new Strike(),
          new Underline(),
          new History(),
        ],
        content: "",
      }),
    };
  },
  watch: {
    id: {
      // call it upon creation too
      immediate: true,
      handler(id) {
        this.$bind(
          "lesson",
          db.collection("lessons").doc(this.$route.params.lesson)
        ).then((lesson) => {
          this.editor.setContent(lesson.content);
          new TradingView.widget({
            width: "100%",
            height: 600,
            // "chart": "QCYDzceT",
            symbol: "bitmex:xbtusd",
            interval: "60",
            timezone: "Etc/UTC",
            theme: "light",
            style: "1",
            locale: "en",
            toolbar_bg: "#f1f3f6",
            enable_publishing: true,
            withdateranges: true,
            hide_side_toolbar: false,
            allow_symbol_change: true,
            // "show_popup_button": true,
            container_id: "live-chart",
          });
        });
      },
    },
  },
  async created() {
    const idTokenResult = await auth.currentUser.getIdTokenResult();
    // Confirm the user is an Admin.
    this.admin = !!idTokenResult.claims.admin;
  },
  beforeDestroy() {
    this.editor.destroy();
  },
  methods: {
    showImagePrompt(command) {
      const src = prompt("Paste the URL of your image here");
      if (src !== null) {
        command({ src });
      }
    },
    async save() {
      const content = this.editor.getHTML();
      await this.$firestoreRefs.lesson.update({
        content: content,
        title: this.lesson.title,
        description: this.lesson.description,
        index: this.lesson.index,
        category: this.lesson.category,
      });
      this.edit = false;
      this.editor.setOptions({
        editable: false,
      });
    },
    cancelEdit() {
      this.edit = false;
      this.editor.setOptions({
        editable: false,
      });
    },
    editMode() {
      this.edit = true;
      this.editor.setOptions({
        editable: true,
      });
    },
    togglePrintMode() {
      this.printMode = !this.printMode
      if (this.printMode) {
        document.getElementById('sbar').style.display = 'none'
        document.getElementById('sbarmobile').style.display = 'none'
        document.getElementById('darkModeButton').style.display = 'none'
      } else {
        document.getElementById('sbar').style.display = 'block'
        document.getElementById('sbarmobile').style.display = 'block'
        document.getElementById('darkModeButton').style.display = 'block'
      }
    }
  },
};
</script>

<style lang="scss">
@import "./src/assets/variables";

.ProseMirror {
  &:focus {
    outline: none;
  }
  ul {
    list-style-type: disc;
    padding-left: 36px;
  }
  h2 {
    color: var(--text-tertiary-color);
    font-size: 2.5rem;
    font-weight: 600;
    line-height: 1.125;
  }

  h2 {
    color: var(--text-tertiary-color);
    font-size: 2.5rem;
    font-weight: 600;
    line-height: 1.125;
  }

  h3 {
    color: var(--text-tertiary-color);
    font-size: 2rem;
    font-weight: 600;
    line-height: 1.125;
  }

  h4 {
    color: var(--text-tertiary-color);
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 1.125;
  }

  h5 {
    color: var(--text-tertiary-color);
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 1.125;
  }

  h6 {
    color: var(--text-tertiary-color);
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.125;
  }

  pre {
    padding: 0.7rem 1rem;
    border-radius: 5px;
    background: $color-black;
    color: $color-white;
    font-size: 0.8rem;
    overflow-x: auto;

    code {
      display: block;
    }
  }

  p code {
    padding: 0.2rem 0.4rem;
    border-radius: 5px;
    font-size: 0.8rem;
    font-weight: bold;
    background: rgba($color-black, 0.1);
    color: rgba($color-black, 0.8);
  }

  ul,
  ol {
    padding-left: 2rem;
  }

  li > p,
  li > ol,
  li > ul {
    margin: 0;
  }

  a {
    color: inherit;
  }

  blockquote {
    border-left: 3px solid rgba($color-black, 0.1);
    color: rgba($color-black, 0.8);
    padding-left: 0.8rem;
    font-style: italic;

    p {
      margin: 0;
    }
  }

  img {
    max-width: 100%;
    border-radius: 3px;
  }

  table {
    border-collapse: collapse;
    table-layout: fixed;
    width: 100%;
    margin: 0;
    overflow: hidden;

    td,
    th {
      min-width: 1em;
      border: 2px solid $color-grey;
      padding: 3px 5px;
      vertical-align: top;
      box-sizing: border-box;
      position: relative;
      > * {
        margin-bottom: 0;
      }
    }

    th {
      font-weight: bold;
      text-align: left;
    }

    .selectedCell:after {
      z-index: 2;
      position: absolute;
      content: "";
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: rgba(200, 200, 255, 0.4);
      pointer-events: none;
    }

    .column-resize-handle {
      position: absolute;
      right: -2px;
      top: 0;
      bottom: 0;
      width: 4px;
      z-index: 20;
      background-color: #adf;
      pointer-events: none;
    }
  }

  .tableWrapper {
    margin: 1em 0;
    overflow-x: auto;
  }

  .resize-cursor {
    cursor: ew-resize;
    cursor: col-resize;
  }
  blockquote,
  h1,
  h2,
  h3,
  ol,
  p,
  pre,
  ul {
    margin: 1rem 0;
  }
}
.editor {
  position: relative;
  &__floating-menu {
    position: absolute;
    z-index: 1;
    margin-top: -0.25rem;
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.2s, visibility 0.2s;
    &.is-active {
      opacity: 1;
      visibility: visible;
      background: lavenderblush;
      position: fixed;
      top: 0;
      padding: 10px;
    }
  }
}
</style>
