<template>
  <section class="dashboard section is-full-touch is-full mobile">
    <div class="container is-fluid-desktop is-full-touch is-full-mobile">
      <div class="is-hidden-desktop is-hidden-widescreen" id="sbarmobile">
        <Mobilemenu />
      </div>
      <div class="columns">
        <div class="column is-narrow-desktop is-hidden-mobile is-hidden-touch sbar" id="sbar">
          <Sidebar />
        </div>
        <div class="column is-full-mobile">
          <div v-if="video">
            <div class="is-flex-desktop is-flex-widescreen is-full-mobile is-justify-content-space-between block">
              <div>
                <h1 class="title mb-1">
                  Video {{ video.index }}: {{ video.title }}
                </h1>
                <nav aria-label="breadcrumbs" class="breadcrumb is-large">
                  <ul>
                    <li>
                      <router-link :to="{ name: 'KozHubVideos' }">
                        <span>in Video Lessons</span>
                      </router-link>
                    </li>
                  </ul>
                </nav>
                <p>{{ video.description }}</p>
              </div>
              <div class="field is-grouped">
                <div class="control">
                  <button
                      @click="togglePrintMode"
                      class="button is-dark"
                  >
                    <span class="icon">
                      <i class="fa fa-print fa-lg"></i>
                    </span>
                  </button>
                </div>
                <p v-if="admin" class="control">
                  <button @click="deleteVideo" class="button is-danger">Delete</button>
                </p>
                <p v-if="admin && !edit" class="control">
                  <button @click="editMode" class="button is-dark">Edit</button>
                </p>
                <p v-if="admin && edit" class="control">
                  <button @click="save" class="button is-success">Save</button>
                </p>
                <p v-if="admin && edit" class="control">
                  <button @click="cancelEdit" class="button is-warning">
                    Cancel
                  </button>
                </p>
              </div>
            </div>
            <div class="is-flex is-justify-content-center">
              <vue-vimeo-player ref="player" :video-id="video.vimeo_id" @ready="onReady" :player-height="height"></vue-vimeo-player>
            </div>
            <div class="card block">
              <div class="card-content">
                <form v-if="admin && edit" class="block" @submit.prevent>
                  <div class="field">
                    <label class="label">Title</label>
                    <p class="control">
                      <input
                        v-model.trim="video.title"
                        class="input"
                        type="text"
                        placeholder="Title"
                      />
                    </p>
                  </div>
                  <div class="field">
                    <label class="label">Description</label>
                    <div class="control">
                      <textarea
                        v-model="video.description"
                        class="textarea"
                        placeholder="Textarea"
                      ></textarea>
                    </div>
                  </div>
                  <div class="field">
                    <label class="label">Vimeo Video ID</label>
                    <p class="control">
                      <input
                          v-model.trim="video.vimeo_id"
                          class="input"
                          type="text"
                          placeholder="Vimeo Video ID"
                      />
                    </p>
                  </div>
                  <div class="field">
                    <label class="label">Index</label>
                    <p class="control">
                      <input
                        v-model.number="video.index"
                        class="input"
                        type="number"
                        placeholder="Index"
                      />
                    </p>
                  </div>
                </form>
                <editor-floating-menu
                  :editor="editor"
                  v-slot="{ commands, isActive, menu }"
                >
                  <div
                    class="editor__floating-menu"
                    :class="{ 'is-active': edit }"
                    :style="`top: ${menu.top}px`"
                  >
                    <button
                      class="menubar__button"
                      :class="{ 'is-active': isActive.italic() }"
                      @click="showImagePrompt(commands.image)"
                    >
                      <i class="fas fa-image"></i>
                    </button>

                    <button
                      class="menubar__button"
                      :class="{ 'is-active': isActive.bold() }"
                      @click="commands.bold"
                    >
                      <i class="fas fa-bold"></i>
                    </button>

                    <button
                      class="menubar__button"
                      :class="{ 'is-active': isActive.italic() }"
                      @click="commands.italic"
                    >
                      <i class="fas fa-italic"></i>
                    </button>

                    <button
                      class="menubar__button"
                      :class="{ 'is-active': isActive.strike() }"
                      @click="commands.strike"
                    >
                      <i class="fas fa-strikethrough"></i>
                    </button>

                    <button
                      class="menubar__button"
                      :class="{ 'is-active': isActive.underline() }"
                      @click="commands.underline"
                    >
                      <i class="fas fa-underline"></i>
                    </button>

                    <button
                      class="menubar__button"
                      :class="{ 'is-active': isActive.code() }"
                      @click="commands.code"
                    >
                      <i class="fas fa-code"></i>
                    </button>

                    <button
                      class="menubar__button"
                      :class="{ 'is-active': isActive.paragraph() }"
                      @click="commands.paragraph"
                    >
                      <i class="fas fa-paragraph"></i>
                    </button>

                    <button
                      class="menubar__button"
                      :class="{ 'is-active': isActive.heading({ level: 1 }) }"
                      @click="commands.heading({ level: 1 })"
                    >
                      H1
                    </button>

                    <button
                      class="menubar__button"
                      :class="{ 'is-active': isActive.heading({ level: 2 }) }"
                      @click="commands.heading({ level: 2 })"
                    >
                      H2
                    </button>

                    <button
                      class="menubar__button"
                      :class="{ 'is-active': isActive.heading({ level: 3 }) }"
                      @click="commands.heading({ level: 3 })"
                    >
                      H3
                    </button>

                    <button
                      class="menubar__button"
                      :class="{ 'is-active': isActive.heading({ level: 4 }) }"
                      @click="commands.heading({ level: 4 })"
                    >
                      H4
                    </button>

                    <button
                      class="menubar__button"
                      :class="{ 'is-active': isActive.heading({ level: 5 }) }"
                      @click="commands.heading({ level: 5 })"
                    >
                      H5
                    </button>

                    <button
                      class="menubar__button"
                      :class="{ 'is-active': isActive.heading({ level: 6 }) }"
                      @click="commands.heading({ level: 6 })"
                    >
                      H6
                    </button>

                    <button
                      class="menubar__button"
                      :class="{ 'is-active': isActive.bullet_list() }"
                      @click="commands.bullet_list"
                    >
                      <i class="fas fa-list-ul"></i>
                    </button>

                    <button
                      class="menubar__button"
                      :class="{ 'is-active': isActive.ordered_list() }"
                      @click="commands.ordered_list"
                    >
                      <i class="fas fa-list-ol"></i>
                    </button>

                    <button
                      class="menubar__button"
                      :class="{ 'is-active': isActive.blockquote() }"
                      @click="commands.blockquote"
                    >
                      <i class="fas fa-quote-left"></i>
                    </button>

                    <button
                      class="menubar__button"
                      :class="{ 'is-active': isActive.code_block() }"
                      @click="commands.code_block"
                    >
                      <i class="fas fa-terminal"></i>
                    </button>

                    <button
                      class="menubar__button"
                      @click="commands.horizontal_rule"
                    >
                      ----
                    </button>

                    <button class="menubar__button" @click="commands.undo">
                      <i class="fas fa-undo"></i>
                    </button>

                    <button class="menubar__button" @click="commands.redo">
                      <i class="fas fa-redo"></i>
                    </button>
                  </div>
                </editor-floating-menu>
                <editor-content :editor="editor" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import {
  Editor,
  EditorContent,
  EditorMenuBar,
  EditorFloatingMenu,
} from "tiptap";
import {
  Blockquote,
  CodeBlock,
  HardBreak,
  Heading,
  HorizontalRule,
  OrderedList,
  BulletList,
  ListItem,
  TodoItem,
  TodoList,
  Bold,
  Code,
  Italic,
  Link,
  Strike,
  Underline,
  History,
  Image,
} from "tiptap-extensions";
import Sidebar from "@/components/Sidebar.vue";
import Mobilemenu from "@/components/Mobilemenu.vue";
import Icon from "@/components/Icon";
import { auth, db } from "@/firebase";
import { vueVimeoPlayer } from 'vue-vimeo-player'
import router from "@/router";

export default {
  name: "KozHubVideosVideo.vue",
  components: {
    Sidebar,
    EditorContent,
    EditorMenuBar,
    EditorFloatingMenu,
    Icon,
    Mobilemenu,
    vueVimeoPlayer
  },
  data() {
    return {
      admin: false,
      edit: false,
      video: null,
      printMode: false,
      videoID: '32001208',
      height: 500,
      options: {
        muted: true,
        autoplay: true,
      },
      playerReady: false,
      editor: new Editor({
        editable: false,
        extensions: [
          new Image(),
          new Blockquote(),
          new BulletList(),
          new CodeBlock(),
          new HardBreak(),
          new Heading({ levels: [1, 2, 3, 4, 5, 6] }),
          new HorizontalRule(),
          new ListItem(),
          new OrderedList(),
          new TodoItem(),
          new TodoList(),
          new Link(),
          new Bold(),
          new Code(),
          new Italic(),
          new Strike(),
          new Underline(),
          new History(),
        ],
        content: "",
      }),
    };
  },
  watch: {
    id: {
      // call it upon creation too
      immediate: true,
      handler(id) {
        this.$bind(
          "video",
          db.collection("kozhub").doc(this.$route.params.video)
        ).then((video) => {
          this.editor.setContent(video.content);
        });
      },
    },
  },
  async created() {
    const idTokenResult = await auth.currentUser.getIdTokenResult();
    // Confirm the user is an Admin.
    this.admin = !!idTokenResult.claims.admin;
  },
  beforeDestroy() {
    this.editor.destroy();
  },
  methods: {
    onReady() {
      this.playerReady = true
    },
    play () {
      this.$refs.player.play()
    },
    pause () {
      this.$refs.player.pause()
    },
    showImagePrompt(command) {
      const src = prompt("Paste the URL of your image here");
      if (src !== null) {
        command({ src });
      }
    },
    async save() {
      const content = this.editor.getHTML();
      await this.$firestoreRefs.video.update({
        content: content,
        title: this.video.title,
        description: this.video.description,
        vimeo_id: this.video.vimeo_id,
        index: this.video.index,
      });
      this.edit = false;
      this.editor.setOptions({
        editable: false,
      });
    },
    cancelEdit() {
      this.edit = false;
      this.editor.setOptions({
        editable: false,
      });
    },
    editMode() {
      this.edit = true;
      this.editor.setOptions({
        editable: true,
      });
    },
    async deleteVideo() {
      if (confirm('Are you sure you want to delete the video from the site?')) {
        await db.collection("kozhub")
            .doc(this.$route.params.video)
            .delete()
        console.log('deleted')
        await this.$router.push({name: "KozHubVideos"});
      }
    },
    togglePrintMode() {
      this.printMode = !this.printMode
      if (this.printMode) {
        document.getElementById('sbar').style.display = 'none'
        document.getElementById('sbarmobile').style.display = 'none'
        document.getElementById('darkModeButton').style.display = 'none'
      } else {
        document.getElementById('sbar').style.display = 'block'
        document.getElementById('sbarmobile').style.display = 'block'
        document.getElementById('darkModeButton').style.display = 'block'
      }
    }
  },
};
</script>

<style lang="scss">
@import "./src/assets/variables";

.ProseMirror {
  &:focus {
    outline: none;
  }
  ul {
    list-style-type: disc;
    padding-left: 36px;
  }
  h2 {
    color: var(--text-tertiary-color);
    font-size: 2.5rem;
    font-weight: 600;
    line-height: 1.125;
  }

  h2 {
    color: var(--text-tertiary-color);
    font-size: 2.5rem;
    font-weight: 600;
    line-height: 1.125;
  }

  h3 {
    color: var(--text-tertiary-color);
    font-size: 2rem;
    font-weight: 600;
    line-height: 1.125;
  }

  h4 {
    color: var(--text-tertiary-color);
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 1.125;
  }

  h5 {
    color: var(--text-tertiary-color);
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 1.125;
  }

  h6 {
    color: var(--text-tertiary-color);
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.125;
  }

  pre {
    padding: 0.7rem 1rem;
    border-radius: 5px;
    background: $color-black;
    color: $color-white;
    font-size: 0.8rem;
    overflow-x: auto;

    code {
      display: block;
    }
  }

  p code {
    padding: 0.2rem 0.4rem;
    border-radius: 5px;
    font-size: 0.8rem;
    font-weight: bold;
    background: rgba($color-black, 0.1);
    color: rgba($color-black, 0.8);
  }

  ul,
  ol {
    padding-left: 2rem;
  }

  li > p,
  li > ol,
  li > ul {
    margin: 0;
  }

  a {
    color: inherit;
  }

  blockquote {
    border-left: 3px solid rgba($color-black, 0.1);
    color: rgba($color-black, 0.8);
    padding-left: 0.8rem;
    font-style: italic;

    p {
      margin: 0;
    }
  }

  img {
    max-width: 100%;
    border-radius: 3px;
  }

  table {
    border-collapse: collapse;
    table-layout: fixed;
    width: 100%;
    margin: 0;
    overflow: hidden;

    td,
    th {
      min-width: 1em;
      border: 2px solid $color-grey;
      padding: 3px 5px;
      vertical-align: top;
      box-sizing: border-box;
      position: relative;
      > * {
        margin-bottom: 0;
      }
    }

    th {
      font-weight: bold;
      text-align: left;
    }

    .selectedCell:after {
      z-index: 2;
      position: absolute;
      content: "";
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: rgba(200, 200, 255, 0.4);
      pointer-events: none;
    }

    .column-resize-handle {
      position: absolute;
      right: -2px;
      top: 0;
      bottom: 0;
      width: 4px;
      z-index: 20;
      background-color: #adf;
      pointer-events: none;
    }
  }

  .tableWrapper {
    margin: 1em 0;
    overflow-x: auto;
  }

  .resize-cursor {
    cursor: ew-resize;
    cursor: col-resize;
  }
  blockquote,
  h1,
  h2,
  h3,
  ol,
  p,
  pre,
  ul {
    margin: 1rem 0;
  }
}
.editor {
  position: relative;
  &__floating-menu {
    position: absolute;
    z-index: 1;
    margin-top: -0.25rem;
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.2s, visibility 0.2s;
    &.is-active {
      opacity: 1;
      visibility: visible;
      background: lavenderblush;
      position: fixed;
      top: 0;
      padding: 10px;
    }
  }
}
</style>
